import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab, Grid, Card, CardContent, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate si estás usando React Router
import img1 from '../../images/Banner/banner1.jpg';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const Info = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate(); // Utiliza useNavigate para redirigir

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInscribirse = (distance, price) => {
    const numericPrice = parseFloat(price.replace(/\./g, '').replace(',', '.')); // Convierte '20.000,00' a 20000.00
    // Redirige al componente Forms con los datos seleccionados
    navigate('/forms', { state: { distance, price: numericPrice } });
  };

  return (
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        padding: 4,
        borderRadius: 2,
        maxWidth: '90%',
        margin: 'auto',
        marginTop: 4,
        marginBottom: 4,
        fontFamily: 'Arial, sans-serif',
        boxShadow: '0px 1px 20px rgba(100, 0, 0, 100)',
      }}
    >
      {/* Banner Image */}
      <Box
        component="img"
        src={img1}
        alt="Banner"
        sx={{
          width: '100%',
          borderRadius: 2,
          marginBottom: 3,
          maxHeight: '300px',
        }}
      />

      {/* Tabs */}
      <Tabs value={value} onChange={handleChange} aria-label="info tabs">
        <Tab label="Inscripción por Distancia" />
        <Tab label="Información de la Carrera" />
        <Tab label="Reglamento" />
      </Tabs>

      {/* Tab Panels */}
      <TabPanel value={value} index={0}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Card
              variant='outlined'
              sx={{
                maxWidth: 300,
                transition: "0.2s",
                boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                borderRadius: 3,
                "&:hover": {
                  transform: "scale(1.05)",
                }
              }}>
              <CardContent>
                <Typography variant="h2" gutterBottom>
                  5K
                </Typography>
                <Typography variant="h5">
                  $20.000,00
                </Typography>
              </CardContent>
              <Box sx={{ textAlign: 'center', paddingBottom: 2 }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleInscribirse('5K', '20.000,00')}
                >
                  Inscribirse
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card
              variant='outlined'
              sx={{
                maxWidth: 300,
                transition: "0.2s",
                boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                borderRadius: 3,
                "&:hover": {
                  transform: "scale(1.05)",
                }
              }}>
              <CardContent>
                <Typography variant="h2" gutterBottom>
                  10K
                </Typography>
                <Typography variant="h5">
                  $30.000,00
                </Typography>
              </CardContent>
              <Box sx={{ textAlign: 'center', paddingBottom: 2 }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleInscribirse('10K', '30.000,00')}
                >
                  Inscribirse
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card
              variant='outlined'
              sx={{
                maxWidth: 300,
                transition: "0.2s",
                boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                borderRadius: 3,
                "&:hover": {
                  transform: "scale(1.05)",
                }
              }}>
              <CardContent>
                <Typography variant="h2" gutterBottom>
                  21K
                </Typography>
                <Typography variant="h5">
                  $40.000,00
                </Typography>
              </CardContent>
              <Box sx={{ textAlign: 'center', paddingBottom: 2 }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleInscribirse('21K', '40.000,00')}
                >
                  Inscribirse
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card
              variant='outlined'
              sx={{
                maxWidth: 300,
                transition: "0.2s",
                boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                borderRadius: 3,
                "&:hover": {
                  transform: "scale(1.05)",
                }
              }}>
              <CardContent>
                <Typography variant="h2" gutterBottom>
                  42K
                </Typography>
                <Typography variant="h5">
                  $50.000,00
                </Typography>
              </CardContent>
              <Box sx={{ textAlign: 'center', paddingBottom: 2 }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleInscribirse('42K', '50.000,00')}
                >
                  Inscribirse
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Typography variant="h6" gutterBottom>
          Información de la Carrera
        </Typography>
        <Typography variant="body1">
          Aquí puedes agregar la información detallada sobre la carrera, como la fecha, lugar,
          horarios, etc.
        </Typography>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Typography variant="h6" gutterBottom>
          Reglamento
        </Typography>
        <Typography variant="body1">
          Aquí puedes cargar o detallar el reglamento de la carrera, incluyendo cualquier
          normativa que los participantes deban seguir.
        </Typography>
      </TabPanel>
    </Box>
  );
};

export default Info;
