import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config/config'
/** */

const UpLoad = () => {
  const [nombreImagen, setNombreImagen] = useState('');
  const [fecha, setFecha] = useState('');
  const [imagen, setImagen] = useState(null);
  const [tablas, setTablas] = useState(['']);
  const [link, setLink] = useState('');

useEffect(() => {
  const obtenerTablas = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/clasificaciones`);
      setTablas(response.data);
    } catch (error) {
      console.error('Error al obtener las tablas:', error);
    }
  };
  obtenerTablas();
}, []);

const handleImagenChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    resizeImage(file, 60, setImagen);
  }
};

const resizeImage = (file, maxSizeKB, callback) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    const img = new Image();
    img.src = reader.result;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      
      let width = img.width;
      let height = img.height;
      const maxSize = maxSizeKB * 1024;

      // Cambio el tamaño sin cambiar la forma
      if (width > height) {
        if (width > 1000) {
          height *= 1000 / width;
          width = 1000;
        }
      } else {
        if (height > 1000) {
          width *= 1000 / height;
          height = 1000;
        }
      }
      
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);
      
      // Si necesito puedo ajustar la calidad de la imagen
      let quality = 0.7;
      let base64String = canvas.toDataURL('image/jpeg', quality);
      while (base64String.length > maxSize && quality > 0.1) {
        quality -= 0.05;
        base64String = canvas.toDataURL('image/jpeg', quality);
      }
      
      fetch(base64String)
        .then(res => res.blob())
        .then(blob => {
          const resizedFile = new File([blob], file.name, { type: 'image/jpeg' });
          callback(resizedFile);
        });
    };
  };
};

  const handleFechaChange = (event) => {
    setFecha(event.target.value);
  };

  const handleNombreImagenChange = (event) => {
    setNombreImagen(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('nombre', nombreImagen);
    formData.append('fecha', fecha);
    formData.append('imagen', imagen);
    formData.append('link', link);
    
    try {
      await axios.post(`${config.API_URL}/subirImagen`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('Imagen subida correctamente');
    } catch (error) {
      console.error('Error al subir la imagen:', error);
      alert('Error al subir la imagen');
    }
  };

  const handleEliminarImagen = async () => {
    try {
      const response = await axios.delete(`${config.API_URL}/eliminarImagen`, { data: { nombreImagen } });
      alert(response.data);
    } catch (error) {
      console.error('Error al eliminar la imagen:', error);
      alert('Error al eliminar la imagen');
    }
  };

  return (
    <div className="container">
      <form className="formin-line" onSubmit={handleFormSubmit}>
        <label
          className="my-1 mr-2 text-light"
          for="inlineFormCustomSelectPref"
        >
          Selecciona una carrera:
          <select
            className="custom-select my-1 mr-sm-2"
            id="inlineFormCustomSelectPref"
            value={nombreImagen}
            onChange={handleNombreImagenChange}
          >
            <option value={""}>-</option>
            {tablas.map((tabla, index) => (
              <option key={index}>{tabla.table_name}</option>
            ))}
          </select>
        </label>
        <br />
        <br />
        <label
          className="my-1 mr-2 text-light"
          for="inlineFormCustomSelectPref"
        >
          Fecha de la carrera:
          <input type="date" value={fecha} onChange={handleFechaChange}></input>
        </label>

        <br />
        <br />

        <div className="mb-3">
          <label for="formFileMultiple" className="form-label text-light">
            <input
              type="file"
              className="form-control"
              id="formFileMultiple"
              onChange={handleImagenChange}
            />
          </label>
        </div>

        <label className="my-1 mr-2 text-light" htmlFor="inlineFormCustomLink">
          Link relacionado:
          <input
            type="text"
            className="form-control"
            id="inlineFormCustomLink"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </label>
        <br />
        <br />

        <button type="submit" className="btn btn-primary">
          Cargar imagen
        </button>
      </form>
      <button className="btn btn-danger mt-3" onClick={handleEliminarImagen}>
        Eliminar Imagen
      </button>
    </div>
  );
};

export default UpLoad;