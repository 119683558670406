import { Button, Card, CardActionArea, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';
import img1 from '../../images/ImgMuestra/img1.png'
import img2 from '../../images/ImgMuestra/img2.png'
import img3 from '../../images/ImgMuestra/img3.png'
import { useNavigate } from 'react-router-dom';

const Inscripciones = () => {
    const navigate = useNavigate();

    const handleClick = () =>{
        navigate('/info')
    };
  

  return (
    <Container maxWidth="lg" style={{ marginTop: '30px' }}>
      <Typography variant='h3' color="white" align='center'>Inscripciones abiertas</Typography>

      <Grid container spacing={5} style={{ marginTop: 1 }} align='center'>

            <Grid item xs={12} sm={4}>
              <Card
                color="danger"
                variant='outlined'
                style={{ padding: "10px", background: "#000000", color: "white" }}
                sx={{
                  maxWidth: 300,
                  transition: "0.2s",
                  boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                  borderRadius: 3,
                  "&:hover": {
                    transform: "scale(1.05)",
                  }
                }}
              >
                <CardActionArea>
                  <CardMedia component="img" src={img1} style={{ height: 300, objectFit: 'fill' }} />
                  <CardContent>
                  <Button variant="contained" color="error" onClick={handleClick}>Inscribirse</Button>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Card
                color="danger"
                variant='outlined'
                style={{ padding: "10px", background: "#000000", color: "white" }}
                sx={{
                  maxWidth: 300,
                  transition: "0.2s",
                  boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                  borderRadius: 3,
                  "&:hover": {
                    transform: "scale(1.05)",
                  }
                }}
              >
                <CardActionArea>
                  <CardMedia component="img" src={img2} style={{ height: 300, objectFit: 'fill' }} />
                  <CardContent>
                  <Button variant="contained" color="error" onClick={handleClick}>Inscribirse</Button>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Card
                color="danger"
                variant='outlined'
                style={{ padding: "10px", background: "#000000", color: "white" }}
                sx={{
                  maxWidth: 300,
                  transition: "0.2s",
                  boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                  borderRadius: 3,
                  "&:hover": {
                    transform: "scale(1.05)",
                  }
                }}
              >
                <CardActionArea>
                  <CardMedia component="img" src={img3} style={{ height: 300, objectFit: 'fill' }} />
                  <CardContent>
                  <Button variant="contained" color="error" onClick={handleClick}>Inscribirse</Button>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
      </Grid>
    </Container>
  );
};

export default Inscripciones;
