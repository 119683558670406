import React, { useState } from 'react';
import { TextField, Box, Typography, Grid, Select, MenuItem, InputLabel, FormControl, Card, CardContent, Button } from '@mui/material';
import { useLocation } from 'react-router-dom'; // Importa useLocation para recibir los datos
import img1 from '../../images/Banner/banner1.jpg';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';

const Forms = () => {
  const { state } = useLocation(); // Obtén los datos pasados por state
  const { distance, price } = state || {}; // Asegura que price sea un número o un valor por defecto
  const [gender, setGender] = useState('');
  const [discountCode, setDiscountCode] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState(null); // Inicializa con el precio que traes de info
  const [preferenceId, setPreferenceId] = useState(null);

  const handleDiscountCodeChange = (event) => {
    setDiscountCode(event.target.value);
  };

  const applyDiscount = () => {
    // Aquí puedes validar el código de descuento y aplicar el descuento si es válido
    if (discountCode === 'DESCUENTO20') { // Ejemplo de código de descuento
      setDiscountedPrice(price * 0.8); // Aplica un 20% de descuento basado en el precio original
    }
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleFormSubmit = async () => {
    const formData = {
      first_name: document.getElementById('first_name').value,
      last_name: document.getElementById('last_name').value,
      document_number: document.getElementById('document_number').value,
      gender,
      birth_date: document.getElementById('birth_date').value,
      age: document.getElementById('age').value,
      email: document.getElementById('email').value,
      phone: document.getElementById('phone').value,
      nationality: document.getElementById('Nation').value,
      province: document.getElementById('Province').value,
      running_team: document.getElementById('Team').value,
      distance,
      price,
      discount_code: discountCode,
      discounted_price: discountedPrice || price,
    };

    try {
      const response = await fetch('http://localhost:5000/api/submit_form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Error al enviar el formulario al backend');
      }

      const data = await response.json();
      console.log('Formulario enviado exitosamente:', data);

      // Después de enviar el formulario, crea la preferencia de Mercado Pago
      await createPreference();

    } catch (error) {
      console.error('Error al enviar el formulario:', error);
    }
  };

  const createPreference = async () => {
    try {
      const response = await fetch('http://localhost:5000/api/create_preference', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          items: [
            {
              title: distance,
              quantity: 1,
              currency_id: 'ARS',
              unit_price: discountedPrice || price,
            },
          ],
        }),
      });

      if (!response.ok) {
        throw new Error('Error al crear la preferencia de Mercado Pago');
      }

      const data = await response.json();
      setPreferenceId(data.id);
    } catch (error) {
      console.error('Error al procesar el pago:', error);
    }
  };

  // Inicializa Mercado Pago con tu public key
  initMercadoPago('TESTUSER675920361');

  return (
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        padding: 4,
        borderRadius: 2,
        maxWidth: '90%',
        margin: 'auto',
        marginTop: 4,
        marginBottom: 4,
        fontFamily: 'Arial, sans-serif',
        boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
      }}
    >
      <Box
        component="img"
        src={img1}
        alt="Banner"
        sx={{
          width: '100%',
          borderRadius: 2,
          marginBottom: 3,
          maxHeight: '300px',
        }}
      />
      <Box
      sx={{
        backgroundColor: '#f5f5f5',
        borderRadius: 2,
        width: '100%',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' }, // Columna en pantallas pequeñas, fila en pantallas grandes
        justifyContent: 'center',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <Box
      sx={{
        backgroundColor: '#f5f5f5',
        padding: 4,
        borderRadius: 2,
        maxWidth: { xs: '100%', md: '70%' },
        fontFamily: 'Arial, sans-serif',
        boxShadow: "0px 1px 10px black",
        marginBottom: { xs: 2, md: 0 }, // Margen inferior en pantallas pequeñas
        marginRight: { xs: 0, md: 10 }, // Margen derecho en pantallas grandes
      }}
    >
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginBottom: 3 }}>
        Datos del corredor
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="first_name"
            label="Nombre"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="last_name"
            label="Apellido"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="document_number"
            label="Número de Documento"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="gender-label">Género</InputLabel>
            <Select
              labelId="gender-label"
              id="gender"
              value={gender}
              onChange={handleGenderChange}
              label="Género"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#ccc',
                  },
                  '&:hover fieldset': {
                    borderColor: '#666',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1976d2',
                  },
                },
              }}
            >
              <MenuItem value="masculino">Masculino</MenuItem>
              <MenuItem value="femenino">Femenino</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="birth_date"
            label="Fecha de Nacimiento"
            variant="outlined"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="age"
            label="Edad al día de la carrera"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="email"
            label="Correo Electrónico"
            variant="outlined"
            type="email"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="phone"
            label="Teléfono"
            variant="outlined"
            type="tel"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="Nation"
            label="Nacionalidad"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="Province"
            label="Provincia"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="Team"
            label="Running Team"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
      </Grid>
    
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginTop: 4 }}>
            Código de Descuento
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="discountCode"
                label="Código"
                variant="outlined"
                value={discountCode}
                onChange={handleDiscountCodeChange}
                sx={{
                  '& .MuiInputLabel-root': { color: '#333' },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#ccc',
                    },
                    '&:hover fieldset': {
                      borderColor: '#666',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1976d2',
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={applyDiscount}
                sx={{ height: '100%' }}
              >
                Aplicar
              </Button>
            </Grid>
          </Grid>

          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginTop: 4 }}>
            Precio Total: ${discountedPrice || price}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleFormSubmit}
            sx={{ marginTop: 4 }}
          >
            Enviar Formulario
          </Button>
        </Box>
        <Box
          sx={{
            backgroundColor: '#f5f5f5',
            padding: 4,
            borderRadius: 2,
            maxWidth: { xs: '100%', md: '30%' },
            fontFamily: 'Arial, sans-serif',
            boxShadow: "0px 1px 10px black",
          }}
        >
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                Información de la Carrera
              </Typography>
              <Typography variant="h6" gutterBottom>
                Distancia: {distance}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Precio: ${price}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Precio con Descuento: ${discountedPrice || price}
              </Typography>
            </CardContent>
          </Card>
          {preferenceId && <Wallet initialization={{ preferenceId }} />}
        </Box>
      </Box>
    </Box>
  );
};

export default Forms;