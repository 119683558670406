import React from 'react'
import logo from '../images/LogoED.png';
import { NavLink } from 'react-router-dom';

/**prueba header top */
const Header = () => {
  return (
    <div>
    
    <header className='container'>
      
      <nav className="navbar navbar-expand-lg navbar-dark top-nav-collapse scrolling-navbar">

        <NavLink to="/home">
          <img src={logo} height="70" alt="No Encontrada"/>
        </NavLink>

      <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto">
            <li><NavLink to="/home" className="nav-item nav-link text center">Inicio</NavLink></li>
            <li><NavLink to="/Carreras" className="nav-item nav-link text center">Carreras</NavLink></li>
            <li><NavLink to="/tutiempo" className="nav-item nav-link text center">Tu Tiempo</NavLink></li>
            <li><NavLink to="/Nosotros" className="nav-item nav-link text center">Nosotros</NavLink></li>
            {/*<li><NavLink to="/Inscripciones" className="nav-item nav-link text center">Inscripciones</NavLink></li>*/}
          </div>
      </div>
     </nav>

    </header>

    </div>

  )
}

export default Header


